import React, {useContext, useEffect, useState} from "react";
import texts from './localization'
import LocaleContext from "../../Standard/LocaleContext";
import {localized} from "../../Standard/utils/localized";
import Text from "../Text";
import {Checkbox} from "antd";
import styled from "styled-components";
import {useWeb3React} from "@web3-react/core";
import {NFT} from "../../types";

type DocumentsPropType = {
  email: string;
  setIsDocValid: (value: boolean) => void;
  project: NFT
}

const DocumentsDefaultProps = {}

const DocumentsContainer = styled.div<{ isExtended: boolean }>`
  transition: all 0.3s;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  max-height: ${p => p.isExtended ? '300px' : '49px'};
  margin-bottom: 32px;
  border-radius: 8px;
  z-index: 2;
  padding-left: 18px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid #E8E8EB;
`

const DocumentsContainerOpenCloseButton = styled.button<{ isExtended: boolean }>`
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5px;
  padding: 0;
  margin: 0;
  outline: none !important;
  border: none !important;
  width: 40px;
  height: 40px;
  right: 10px;
  z-index: -1;
  transform: ${p => p.isExtended ? 'rotate(180deg)' : 'rotate(0deg)'};
`

const DocumentLink = styled.a`
  color: #5790FF;
  text-decoration: underline;
  margin-left: 10px;
`

const Documents = (props: DocumentsPropType) => {
  const {locale} = useContext(LocaleContext)
  const {email, setIsDocValid, project} = props;
  const {account, active} = useWeb3React()

  const [docksFromLocal, setDocksFromLocal] = useState<{
    "amlPolicy": boolean,
    "dataProtectionPolicy": boolean,
    "termsAndConditions": boolean,
    "refundPolicy": boolean,
    "trustDoc": boolean
  } | null>(null)

  const [amlPolicy, setAmlPolicyCheck] = useState<boolean>(false)
  const [dataProtectionPolicy, setDataProtectionPolicyCheck] = useState<boolean>(false)
  const [termsAndConditions, setTermsAndConditionsCheck] = useState<boolean>(false)
  const [refundPolicy, setRefundPolicyCheck] = useState<boolean>(false)
  const [trustDoc, setTrustDocCheck] = useState<boolean>(false)


  const getDocksFromLocal = () => {
    const docksFromLocal = localStorage.getItem("documentSignedState");
    const parsedDocumentSignedState = JSON.parse(`${docksFromLocal}`)
    setDocksFromLocal(parsedDocumentSignedState)
  }

  const setLocalDocksStatus = () => {
    localStorage.setItem('documentSignedState',
      JSON.stringify({
        login: email,
        wallet: account,
        amlPolicy,
        dataProtectionPolicy,
        termsAndConditions,
        refundPolicy,
        trustDoc
      })
    )
  }

  useEffect(() => {
    if (docksFromLocal) {
      setLocalDocksStatus()
    }
  }, [email, account, amlPolicy, dataProtectionPolicy, termsAndConditions, refundPolicy, trustDoc])

  useEffect(() => {
    getDocksFromLocal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, active])

  const docksValid =
    amlPolicy &&
    dataProtectionPolicy &&
    termsAndConditions &&
    refundPolicy &&
    trustDoc

  const [isDocumentsExtended, setIsDocumentsExtended] = useState(!docksValid)

  useEffect(() => {
    if (docksFromLocal) {
      setAmlPolicyCheck(docksFromLocal.amlPolicy)
      setRefundPolicyCheck(docksFromLocal.refundPolicy)
      setTrustDocCheck(docksFromLocal.trustDoc)
      setTermsAndConditionsCheck(docksFromLocal.termsAndConditions)
      setDataProtectionPolicyCheck(docksFromLocal.dataProtectionPolicy)
    }
  }, [docksFromLocal])

  useEffect(() => {
    setTimeout(() => {
      setIsDocumentsExtended(!docksValid)
    }, 500)
  }, [docksValid])

  useEffect(() => {
    setIsDocValid(docksValid)
  }, [docksValid])

  return (
    <DocumentsContainer isExtended={isDocumentsExtended}>
      <Text className={'flex grow-0 items-center'} fontSize={20} fontWeight={600}>
        Documents
      </Text>
      <DocumentsContainerOpenCloseButton onClick={() => setIsDocumentsExtended(!isDocumentsExtended)}
                                         isExtended={isDocumentsExtended}>
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.58581 6.65685L0.636062 1.70711C0.245538 1.31658 0.245538 0.683418 0.636062 0.292893V0.292893C1.02659 -0.097631 1.65975 -0.097631 2.05028 0.292893L6.8586 5.10122C6.93671 5.17932 7.06334 5.17932 7.14144 5.10122L11.9498 0.292893C12.3403 -0.097631 12.9735 -0.097631 13.364 0.292893V0.292893C13.7545 0.683418 13.7545 1.31658 13.364 1.70711L7.70713 7.36396C7.31661 7.75449 6.68344 7.75449 6.29292 7.36396L5.58581 6.65685Z"
            fill="#181833"/>
        </svg>
      </DocumentsContainerOpenCloseButton>
      <div style={{marginBottom: '16px'}}/>
      <Text fontSize={16} fontWeight={500}>
        {localized(texts.documentsCheckboxText, locale)}
      </Text>
      <div style={{marginBottom: '10px'}}/>
      <Checkbox onChange={(e) => setAmlPolicyCheck(e.target.checked)} checked={amlPolicy}>
        <DocumentLink href={'/documents/amlPolicy.pdf'} target={'_blank'}>AML Policy</DocumentLink>
      </Checkbox>
      <Checkbox onChange={(e) => setDataProtectionPolicyCheck(e.target.checked)}
                checked={dataProtectionPolicy}>
        <DocumentLink href={'/documents/dataProtectionPolicy.pdf'} target={'_blank'}>Data Protection
          Policy</DocumentLink>
      </Checkbox>
      <Checkbox onChange={(e) => setTermsAndConditionsCheck(e.target.checked)}
                checked={termsAndConditions}>
        <DocumentLink href={'/documents/termsAndConditions.pdf'} target={'_blank'}>Terms And
          Conditions</DocumentLink>
      </Checkbox>
      <Checkbox onChange={(e) => setRefundPolicyCheck(e.target.checked)} checked={refundPolicy}>
        <DocumentLink href={'/documents/refundPolicy.pdf'} target={'_blank'}>Refund Policy</DocumentLink>
      </Checkbox>
      <Checkbox onChange={(e) => setTrustDocCheck(e.target.checked)} checked={trustDoc}>
        <DocumentLink href={'/documents/InvestorAccreditedStatus.pdf'} target={'_blank'}>Investor
          Accredited
          Status</DocumentLink>
      </Checkbox>
    </DocumentsContainer>
  )
};

Documents.defaultProps = DocumentsDefaultProps

export default Documents