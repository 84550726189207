import React, {useContext, useEffect, useState} from "react";
import texts from './localization'
import {localized} from "../../Standard/utils/localized";
import './index.css'
import {ArtworkImage} from "../NFTTile/styled";
import styled from "styled-components";
import {NFT, Token} from "../../types";
import {useHistory, useParams} from "react-router-dom";
import {AllProjects} from "../../mocks/AllProjects"
import Text from '../Text';
import LocaleContext from "../../Standard/LocaleContext";
import CurrentNFTGallerySkeleton from "../CurrentNFTGallerySkeleton";

type CurrentNftGalleryPropType = {
  project: NFT,
  tokens: any
}

const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;  
  max-width: 416px;
  margin-right: 50px;

  @media screen and (max-width: 1240px) {
    max-width: 300px;
  }

  @media screen and (max-width: 1240px) {
    align-items: center;
    max-width: 100%;
    width: 100%;
    margin-right: 0;
  }
`

const OtherTiersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @media screen and (max-width: 900px) {
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
`

const ArtworkImageWrapper = styled.div`
  cursor: pointer;
`

const CurrentNFTGallery = (props: CurrentNftGalleryPropType) => {

  const params: { id: string } = useParams()
  const {project, tokens} = props
  const {locale} = useContext(LocaleContext)

  const history = useHistory()

  const [currentNftCreative, setCurrentNftCreative] = useState<string>()
  const [otherTiers, setOtherTiers] = useState<Token[] | undefined>(undefined)

  const setOtherTokensInCollection = () => {
    if(tokens && Object.keys(tokens).length) {
      const filteredTokens = tokens.filter((token: Token) => token.nftId <= 4)

      if (filteredTokens.length > 0) {
        const otherTokensInCollection = filteredTokens.filter((token: Token) => token.nftId !== tokens[params.id.slice(params.id.length - 1)]?.nftId)
        setOtherTiers(otherTokensInCollection)
      }
    }
  }

  useEffect(() => {
    if (project) {
      // @ts-ignore
      setCurrentNftCreative(AllProjects[project.name].nftsCreativeLinks[params.id.slice(params.id.length - 1)])
    }
  }, [project, params.id.slice(params.id.length - 1)])

  useEffect(() => {
    setOtherTokensInCollection()
  }, [tokens, params.id.slice(params.id.length - 1)])

  return (
    <>
      <ColumnFlex>
        <ArtworkImage className={`${!(project) && 'skeleton'}`} key={currentNftCreative} minWidth={416}
                      minHeights={416} maxWidth={416} playsInline autoPlay loop muted>
          <source src={currentNftCreative}/>
        </ArtworkImage>
        <Text fontWeight={600} fontSize={20} color={'rgba(24, 24, 51, .5)'}>{localized(texts.otherTiers, locale)}</Text>
        <OtherTiersWrapper>
          {
            otherTiers && otherTiers.length ? otherTiers.map(tiers => (
                <ArtworkImageWrapper key={tiers.nftId} onClick={() => history.push(`/nfts/${project.name}-${tiers?.nftId}`)}>
                  <ArtworkImage
                    minWidth={200}
                    minHeights={200}
                    maxWidth={200}
                    maxHeightMobile={140}
                    maxWidthMobile={140}
                    maxWidthAfter1000={105}
                    maxHeightAfter1000={105}
                    maxHeightAfter900={90}
                    maxWidthAfter900={90}
                    autoPlay
                    playsInline
                    loop
                    muted
                    key={tiers.nftCreativeLink}
                  >
                    <source src={tiers.nftCreativeLink}/>
                  </ArtworkImage>
                </ArtworkImageWrapper>
              ))
              :
              <CurrentNFTGallerySkeleton elementsCount={4}/>
          }
        </OtherTiersWrapper>
      </ColumnFlex>
    </>
  )
};

export default CurrentNFTGallery