import React, {useContext, useEffect, useState} from "react";
import "./index.scss";
import {useParams} from "react-router-dom";
import styled from 'styled-components'
import ProjectContext from "../../utils/ProjectsContext";
import ReturnBackPanel from "../../components/ReturnBackPanel";
// @ts-ignore
import CurrentProjectWrapper from "../../components/CurrentProjectWrapper";
import CurrentNFTGallery from "../../components/CurrentNFTGallery";
// @ts-ignore
import CurrentNFTDescription from "../../components/CurrentNFTDescription";
// @ts-ignore
import CurrentNFTBuyForm from "../../components/CurrentNFTBuyForm";
import {AllProjects} from "../../mocks/AllProjects";
import NFTMarketplaceSubHeader from "../../components/SubHeader/variants/NftMarketplace";
import GradientCircles from "../../Standard/decorations/GradientCircles";
import {NFT, Token} from "../../types";
import {Contract} from "ethers";
import CurrentNft from "../../contract/CurrentNft.json";
import {useWeb3React} from "@web3-react/core";
import {useWeb3} from "../../Standard/hooks/useCommonContracts";
import {useBalanceOfBUSD} from "../../hooks/useBalance";

const CurrentNFTContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 4;
`

const Container = styled.div`
  padding-left: 80px;
  padding-right: 80px;
  max-width: 1500px;
  position: relative;

  @media screen and (max-width: 900px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`

const CurrentNFTWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and (max-width: 1240px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`
const CoverAndInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 2;
`

const CurrentNFT = () => {
  const params: { id: string } = useParams()
  const {projects, tokens, setTokens} = useContext(ProjectContext)

  const {account} = useWeb3React()
  const web3 = useWeb3();

  const {balance, updateBalance} = useBalanceOfBUSD()

  const project = projects[params.id.split("-")[0]]

  const [currentTokenOfCollection, setCurrentTokenOfCollection] = useState(undefined)
  const [otherTokensOfCollection, setOtherTokensOfCollection] = useState(undefined)

  const getAllNfts = async (project: NFT) => {
    const NFTArrayFromContract: Token[] = []

    let newNftContract: Contract | undefined

    const abi = CurrentNft.abi;
    if (project.projectAddress) {
      // @ts-ignore
      newNftContract = new web3.eth.Contract(abi, project.projectAddress);
    }

    for (let i = 0; i < 99999; i++) {
      let newToken: Token
      try {
        newToken = {
          ...(await newNftContract?.methods.tokensInfo(`${i}`).call()),
          nftId: i,
          nftCreativeLink: AllProjects[project.name].nftsCreativeLinks[i],
          projectId: project.projectId
        }
        if (account) {
          newToken = {
            ...newToken,
            userAllocation: await newNftContract?.methods.userAllocation(`${i}`, account).call()
          }
        }
      } catch (e) {
        break
      }

      NFTArrayFromContract.push(newToken)

      if (NFTArrayFromContract.length >= 5) {
        setTokens({...tokens, [`${project.name}`]: NFTArrayFromContract})
      }
    }
  }

  useEffect(() => {
    if (!currentTokenOfCollection && project) {
      getAllNfts(projects[params.id.split("-")[0]])
    }
  }, [project])

  useEffect(() => {
    if (project && tokens) {
      setCurrentTokenOfCollection(tokens[params.id.split("-")[0]][+params.id.split("-")[1]])
      setOtherTokensOfCollection(tokens[params.id.split("-")[0]])
    }
  }, [tokens, +params.id.split("-")[1]])

  return (
    <>
      <CurrentNFTContainer>
        <NFTMarketplaceSubHeader
          subtitle={`${AllProjects[params.id.split("-")[0]].name} Tier ${+params.id.split("-")[1] + 1}`}
        />
        <Container>
          <ReturnBackPanel href={`/projects/${params.id.split("-")[0]}`}/>
          <CurrentProjectWrapper project={project}>
            <CurrentNFTWrapper>
              <CurrentNFTGallery project={project} tokens={otherTokensOfCollection}/>
              <CoverAndInfoWrapper>
                <CurrentNFTDescription token={currentTokenOfCollection} balance={balance} updateBalance={updateBalance}/>
                <CurrentNFTBuyForm token={currentTokenOfCollection} project={project} updateBalance={updateBalance} balance={balance}/>
              </CoverAndInfoWrapper>
            </CurrentNFTWrapper>
          </CurrentProjectWrapper>
        </Container>
      </CurrentNFTContainer>
      <GradientCircles/>
    </>
  )
};

export default CurrentNFT

