import React, {useContext} from "react";
import styled from "styled-components";
import {Token} from "../../types";
import {wei2eth} from "../../Standard/utils/common";
import Text from '../Text'
import texts from './localization'
import {localized} from "../../Standard/utils/localized";
import LocaleContext from "../../Standard/LocaleContext";

type NFTTransferFormPropType = {
  nft: Token
}

const NFTTransferFormDefaultProps = {}

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const FlexWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 7px;
`

const NFTTransferForm = (props: NFTTransferFormPropType) => {
  const {nft} = props
  const {locale} = useContext(LocaleContext)

  return (
    <TextWrapper>
      <Text fontWeight={600} fontSize={20}>{localized(texts.finalAllocation, locale)}</Text>
      <FlexWrapper>
        <Text fontWeight={600} fontSize={24}>{`${wei2eth(nft.userAllocation)}`}</Text>
        <Text fontWeight={400} fontSize={22}>BUSD</Text>
      </FlexWrapper>
    </TextWrapper>
  )
};

NFTTransferForm.defaultProps = NFTTransferFormDefaultProps

export default NFTTransferForm