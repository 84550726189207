import React from "react";
import styled, {css} from "styled-components";

type TextPropType = {
  fontWeight?: number
  fontSize?: number
  marginBottom?: number
  marginTop?: number,
  color?: string
  children: string | React.ReactNode,
  adaptiveFontWeight?: number,
  adaptiveMarginBottom?: number,
  className?: string,
  borderRadius?: number,
  height?: number
}

const TextDefaultProps = {
  fontWeight: 700,
  fontSize: 20,
  marginBottom: 0,
  marginTop: 0,
  color: '#181833',
}

const CustomText = styled.span<TextPropType>`
  font-weight: ${p => p.fontWeight};
  font-size: ${p => p.fontSize}px;
  border-radius: ${p => p.borderRadius}px;
  height: ${p => p.height}px;
  
  .standard-text {
    margin-left: 0.4em;
  }
  .standard-text:first-child {
    margin-left: 0;
  }
  
  ${({marginBottom, color, marginTop}) => {
    return css`
      margin-bottom: ${marginBottom}px;
      color: ${color};
      margin-top: ${marginTop}px;
    `;
  }};

  @media screen and (max-width: 900px) {
    font-size: ${p => p.adaptiveFontWeight}px;
    margin-bottom: ${p => p.adaptiveMarginBottom}px;
  }
`

const Text = (props: TextPropType) => {

  const {
    fontSize,
    color,
    fontWeight,
    marginBottom,
    children,
    marginTop,
    adaptiveFontWeight,
    adaptiveMarginBottom,
    className,
    borderRadius,
    height
  } = props

  return (
    <CustomText
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      marginBottom={marginBottom}
      marginTop={marginTop}
      adaptiveFontWeight={adaptiveFontWeight}
      adaptiveMarginBottom={adaptiveMarginBottom}
      className={`${className} standard-text`}
      borderRadius={borderRadius}
      height={height}
    >
      {children}
    </CustomText>
  )
};

Text.defaultProps = TextDefaultProps

export default Text