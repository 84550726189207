import React, {useContext, useEffect, useState} from "react";
import LocaleContext from "../../Standard/LocaleContext";
import './index.css'
import {useWeb3React} from "@web3-react/core";
import styled from "styled-components";
import Notification from "../../components/Notification";
import {ArtworkImage} from "../../components/NFTTile/styled";
import NftProjectContainer from "../../components/NftProjectContainer";
import ProjectsContext from "../../utils/ProjectsContext";
import {NFT, ProjectsDict, Token} from "../../types";
import {AllProjects} from "../../mocks/AllProjects";
import NFTTransferForm from "../../components/NFTTransferForm";
import WalletConnectorBubbleContext from "../../Standard/WalletConnectorBubbleContext";
import Spinner from "../../Standard/components/Spinner";
import {localized} from "../../Standard/utils/localized";
import texts from './localization'
import ReturnBackPanel from "../../components/ReturnBackPanel";
import Text from '../../components/Text';
import NFTMarketplaceSubHeader from "../../components/SubHeader/variants/NftMarketplace";
import GradientCircles from "../../Standard/decorations/GradientCircles";
import {Contract} from "ethers";
import CurrentNft from "../../contract/CurrentNft.json";
import {useWeb3} from "../../Standard/hooks/useCommonContracts";
import {useAllocationMarketplaceContract} from "../../hooks/useMarketplaceContract";

const CollectionWrapper = styled.div`
  width: 100%;
  padding-right: 80px;
  padding-left: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 4;

  @media screen and (max-width: 900px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`

const NFTWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
`

const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 30px;
`

const Collection = (props: { isOpen?: boolean }) => {
  const {locale} = useContext(LocaleContext)
  const {projects, setProjects} = useContext(ProjectsContext)
  const {setBubbleValue} = useContext(WalletConnectorBubbleContext)
  const {isOpen} = props
  const {account} = useWeb3React()

  const [allProjects, setAllProjects] = useState<ProjectsDict>({})
  const marketplaceContract = useAllocationMarketplaceContract()

  const web3 = useWeb3()

  useEffect(() => {
    if (isOpen) {
      setBubbleValue("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  async function getAllProjects() {
    let newProjects: ProjectsDict = {}
    for (let i = 0; i < 99999; i++) {
      let newProject: NFT
      try {
        newProject = {...(await marketplaceContract.methods.projects(i).call()), projectId: i}
      } catch(e) {
        break
      }
      const tokens = await getAllNfts(newProject)
      newProjects[newProject.name] = {...newProject, tokens: tokens}
    }
    setAllProjects(newProjects)
  }

  async function getAllNfts(project: NFT) {
    const NFTArrayFromContract: Token[] = []

    let newNftContract: Contract | undefined
    const abi = CurrentNft.abi;
    if (project.projectAddress) {
      // @ts-ignore
      newNftContract = new web3.eth.Contract(abi, project.projectAddress);
    }

    for (let i = 0; i < 99999; i++) {
      let newProject: Token
      try {
        newProject = {
          ...(await newNftContract?.methods.tokensInfo(`${i}`).call()),
          nftId: i,
          nftCreativeLink: AllProjects[project.name].nftsCreativeLinks[i],
          projectId: project.projectId
        }
        if (account) {
          newProject = {
            ...newProject,
            userAllocation: await newNftContract?.methods.userAllocation(`${i}`, account).call()
          }
        }
      } catch(e) {
        break
      }

      NFTArrayFromContract.push(newProject)
    }
    return (NFTArrayFromContract)
  }

  const userProjectsWithAllocation = Object.keys(allProjects).map((name) => {

    const projectsWithAllocation = (allProjects[name].tokens || []).filter((nft: Token) => {
      return nft.userAllocation && +nft.userAllocation > 0
    })

    if (projectsWithAllocation.length === 0) {
      return null
    }

    return (
      <NftProjectContainer key={name} name={AllProjects[name].name}>
        {projectsWithAllocation.map((nft: Token, index: number) => {
          return (
            <NFTWrapper key={nft.nftId}>
              <ArtworkImage maxWidth={350}>
                <source src={AllProjects[name].nftsCreativeLinks[index]}/>
              </ArtworkImage>
              <NFTTransferForm nft={nft}/>
            </NFTWrapper>
          )
        })}
      </NftProjectContainer>
    )
  })

  useEffect(() => {
    getAllProjects()
  }, [])

  return (
    <>
      <CollectionWrapper>
        <NFTMarketplaceSubHeader subtitle={localized(texts.myCollection, locale)}/>
        <ReturnBackPanel href={`/`}/>
        {!account &&
          <>
            <div style={{marginTop: 40}}/>
            <Notification body={localized(texts.connectWalletPrompt, locale)}/>
          </>
        }
        {account &&
          <>
            <FlexWrapper>
              {Object.keys(allProjects).length ?
                <>
                  {
                    userProjectsWithAllocation.every(project => project === null)
                      ?
                      <Text fontSize={24} fontWeight={600}>{localized(texts.dontHaveNfts, locale)}</Text>
                      :
                      <>{userProjectsWithAllocation}</>
                  }
                </>
                :
                <Spinner color={'#04C35C'} size={25}/>
              }
            </FlexWrapper>
          </>
        }
      </CollectionWrapper>
      <GradientCircles/>
    </>
  )
};

export default Collection

