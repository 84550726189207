import React, {useContext, useEffect, useState} from "react";
import texts from './localization'
import LocaleContext from "../../Standard/LocaleContext";
import {localized} from "../../Standard/utils/localized";
import {useWeb3React} from "@web3-react/core";
import ButtonV2 from "../../Standard/components/ButtonV2";
import SimpleLabelContainer from "../../Standard/components/SimpleLabelContainer";
import SimpleInput from "../../Standard/components/SimpleInput";
import {wei2eth} from "../../Standard/utils/common";
import Documents from "../Documents";
import {NFT, Token} from "../../types";
import styled from "styled-components";
import Spinner from "../../Standard/components/Spinner";
import fromExponential from "from-exponential";
import {useBUSDContract, usePancakeRouterContract} from "../../Standard/hooks/useCommonContracts";
import useValidatedState, {validationFuncs} from "../../Standard/hooks/useValidatedState";
import BigNumber from "bignumber.js";
import NotificationIcon from "../../Standard/icons/notificationIcon";
import NotificationContext from "../../Standard/utils/NotificationContext";
import {useParams} from "react-router-dom";
import {getBUSDAddress, getMMProAddress} from "../../Standard/utils/getCommonAdress";
import {useNftContract} from "../../hooks/useNftContract";
import WalletConnectorBubbleContext from "../../Standard/WalletConnectorBubbleContext";
import CollectionContext from "../../utils/CollectionContext";
import {useBalanceOfBUSD} from "../../hooks/useBalance";
import Notification from "../Notification";

const ALLOWANCE = 10 ** 10 * 10 ** 18

const SLIPPAGE_PERCENT = 0.93

const DEADLINE_OVER_NOW = 1000

type CurrentNFTBuyFormPropType = {
  token: Token | undefined;
  project: NFT;
  updateBalance: () => void;
  balance: string
}

const CurrentNFTBuyFormDefaultProps = {}

const SpinnerContainer = styled.div`
  position: absolute;
  right: 10px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const CurrentNFTBuyForm = (props: CurrentNFTBuyFormPropType) => {
  const {token, project, updateBalance, balance} = props
  const {account, active} = useWeb3React()
  const params: { id: string } = useParams()

  const {setBubbleValue} = useContext(WalletConnectorBubbleContext)
  const notificationContext = useContext(NotificationContext)
  const {locale} = useContext(LocaleContext)
  const collectionContext = useContext(CollectionContext)

  const busdContract = useBUSDContract()
  const pancakeRouterContract = usePancakeRouterContract();
  const isSkeleton = !project || !token
  const currentNftContract = useNftContract(project ? project.projectAddress : undefined)

  const [isLoading, setIsLoading] = useState(false)
  const [allowance, setAllowance] = useState<string>('0')
  const [isApproveLoading, setIsApproveLoading] = useState(false)

  const [[email, setEmail], emailValid] = useValidatedState<string>("", validationFuncs.isEmail)
  const [[allocationAmountBusd, setAllocationAmountBusd], allocationAmountBusdValid] = useValidatedState<string>('', newValue => !isNaN(+newValue) && (!token || +newValue <= wei2eth(token.maxAllocation)))

  const [isDocValid, setIsDocValid] = useState(false)
  const isValid =
    token &&
    project &&
    emailValid &&
    allocationAmountBusdValid &&
    email !== undefined &&
    allocationAmountBusd !== undefined &&
    email !== '' &&
    allocationAmountBusd !== '' &&
    isDocValid &&
    // isUserVerified &&
    new BigNumber(allocationAmountBusd).multipliedBy(1000000000000000000).isLessThanOrEqualTo(token.maxAllocation)

  const getDeadline = () => {
    return Math.floor(new Date().getTime() / 1000) + DEADLINE_OVER_NOW;
  }

  const getAllowance = async (): Promise<string> => {
    return await busdContract
      .methods
      // @ts-ignore
      .allowance(account, project.projectAddress)
      .call()
  }

  async function updateAllowance() {
    if (token && project) {
      const newAllowance = await getAllowance()
      setAllowance(newAllowance)
    }
  }

  const approve = async () => {

    if (isApproveLoading) {
      return
    }

    setIsApproveLoading(true)
    const amount2eth = fromExponential(ALLOWANCE);
    try {
      if (token) {
        await busdContract
          .methods
          .approve(project.projectAddress, amount2eth)
          .send({from: account}).once('receipt', () => {
            updateAllowance()
            setIsApproveLoading(false)
          });
      }
    } catch (e) {
      setIsLoading(false)
    }
  };

  const isApprovalRequired = () => !isSkeleton && !!token && (parseInt(allowance) < parseInt(token.price.toString()))

  const getMinAmountOut = async () => {
    const path = [getBUSDAddress(), getMMProAddress()]
    return new BigNumber((await pancakeRouterContract
      .methods
      .getAmountsOut(allocationAmountBusd, path)
      .call())[1])
  }

  async function mintAndAllocate() {
    if (isValid) {
      // const {encryptedEmail} = await encryptEmail(email)
      const tokenId = +params.id.split('-')[1]
      const amountOutMin = (await getMinAmountOut()).multipliedBy(SLIPPAGE_PERCENT).toFixed(0).toString()
      const allocationAmount = (new BigNumber(10).pow(18).multipliedBy(+allocationAmountBusd)).toString()
      const deadline = getDeadline()

      try {
        if (currentNftContract && tokenId.toString() && amountOutMin && allocationAmount && deadline) {
          await currentNftContract
            .methods
            .mintAndAllocate(
              tokenId.toString(),
              amountOutMin,
              deadline,
              allocationAmount,
              []
            ).send({from: account}).once('receipt', () => {
              setBubbleValue("EMPTY")
              setIsLoading(false)
              notificationContext.displayNotification(
                `${localized(texts.successNotification, locale)} ${+allocationAmountBusd + parseInt(wei2eth(token?.price).toString())} BUSD`,
                '',
                <NotificationIcon/>
              )
              setEmail('')
              setAllocationAmountBusd('')
              collectionContext.setCollectionBubbleValue(collectionContext.bubbleCount + 1)
            });
        }
      } catch (e) {
        console.log(e)
        notificationContext.displayNotification(
          `${localized(texts.transactionFailedTitle, locale)}`,
          `${localized(texts.transactionFailedSubtitle, locale)}`,
          <NotificationIcon/>
        )
        setIsLoading(false)
      }
    }
  }

  const handleBuy = async () => {
    const isBalanceValid =
      token &&
      allocationAmountBusd &&
      (+allocationAmountBusd + parseInt(wei2eth(token.price).toString()) <= parseInt(wei2eth(balance).toString()))

    if (!isBalanceValid) {
      notificationContext.displayNotification(
        localized(texts.notificationInsufficientTitle, locale),
        '',
        <NotificationIcon/>
      )
      return
    }

    if (isLoading) {
      return
    }

    if (!isValid && !isApprovalRequired()) {
      return
    }

    setIsLoading(true)
    try {
      await mintAndAllocate()
      await updateBalance()
    } catch (e) {
      console.log({error: e})
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (active) {
      updateAllowance()
      updateBalance()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, token, project])

  return (
    <>
      {(!account && !isSkeleton) && <Notification body={localized(texts.connectWalletToAllocate, locale)}/>}
      {(account && isApprovalRequired()) &&
        <ButtonV2
          isValid
          outlined
          onClick={isSkeleton ? () => {
          } : approve}
        >
          {localized(texts.approveButton, locale)}
          {isApproveLoading &&
            <SpinnerContainer>
              <Spinner color={'#33CC66'} size={25}/>
            </SpinnerContainer>
          }
        </ButtonV2>
      }
      {(account && !isApprovalRequired()) &&
        <Wrapper>
          <SimpleLabelContainer
            label={localized(texts.placeholderEmail, locale)}
          >
            <SimpleInput
              onlyEmmitOnBlur
              onChangeRaw={setEmail}
              isValid={email === "" || emailValid}
              required
              errorTooltipText={localized(texts.tooltipPleaseEnterCorrectEmail, locale)}
              autoComplete="email"
              inputProps={{
                className: `w-full ${isSkeleton ? "skeleton" : ''}`,
                placeholder: localized(texts.placeholderEmail, locale),
                type: 'email',
                value: email
              }}
            />
          </SimpleLabelContainer>
          <SimpleLabelContainer
            label={localized(texts.placeholderYourAllocation, locale)}
          >
            <SimpleInput
              onlyEmmitOnBlur
              hasDefaultValueButton
              defaultValueButtonText={localized(texts.defaultButtonMax, locale)}
              defaultValue={wei2eth(token ? token.maxAllocation : 0).toString()}
              required
              onChangeRaw={setAllocationAmountBusd}
              isValid={allocationAmountBusd === "" || allocationAmountBusdValid}
              errorTooltipText={localized(texts.tooltipAmountCannotExceedMaxAllocation, locale)}
              inputProps={{
                className: `w-full ${isSkeleton ? "skeleton" : ''}`,
                placeholder: localized(texts.placeholderYourAllocation, locale),
                value: allocationAmountBusd
              }}
            />
          </SimpleLabelContainer>
          <Documents email={email} setIsDocValid={setIsDocValid} project={project}/>
          <ButtonV2
            className={`${isSkeleton && 'skeleton'}`}
            isValid={isValid}
            outlined
            onClick={isSkeleton ? () => {
            } : handleBuy}
          >
            {localized(texts.allocateButton, locale)}
            <SpinnerContainer>
              <Spinner color={'#33CC66'} size={isLoading ? 25 : 0}/>
            </SpinnerContainer>
          </ButtonV2>
        </Wrapper>
      }
    </>
  )
};

CurrentNFTBuyForm.defaultProps = CurrentNFTBuyFormDefaultProps

export default CurrentNFTBuyForm