import React, {useContext,} from "react";
import {useHistory} from "react-router-dom";
import styled, {css} from "styled-components";
import {NFT} from "../../types";
import {AllProjects} from "../../mocks/AllProjects";
import LocaleContext from "../../Standard/LocaleContext";
import {localized} from "../../Standard/utils/localized";
import texts from './localization'
import LimitedOfferIcon from '../../icons/LimitedOffer'
import ExclusiveIcon from '../../icons/ExclusiveIcon'

// @ts-ignore
import Text from '../Text';

interface TextProps {
  fontSize: number
  fontWeight: number
  marginBottom?: number
  marginTop?: number
}

type NFTTilePropType = {
  project: NFT | undefined
  isProjectCollection: boolean;
}

const NFTTileDefaultProps = {
  isProjectCollection: false,
}

const TileWrapper = styled.div<{ isProjectCollection: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: ${p => !p.isProjectCollection ? '1px solid #E8E8EB' : 'none'};
  border-radius: ${p => !p.isProjectCollection ? '16px' : '16px 0 0 16px'};
  width: 305px;
  min-height: 450px;
  cursor: ${p => !p.isProjectCollection ? 'pointer' : 'default'};

  @media screen and (max-width: 900px) {
    width: 300px;
    min-height: 500px;
    height: max-content;
  }

  ${({isProjectCollection}) =>
          isProjectCollection &&
          css`
            @media screen and (max-width: 720px) {
              display: none;
            }
          `};
`

const NFTCoverWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 280px;
  padding-top: 8px;
`

const NFTInfoWrapper = styled.div`
  width: 100%;
  padding: 22px 32px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
`

const NFTDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
  padding: 20px 32px;
  font-weight: 500;
  font-size: 14px;
  color: rgba(24, 24, 51, .5);
  padding-bottom: 45px;
`

const Logo = styled.video`
  width: 280px;
  height: 280px;
  border-radius: 8px;
  margin-bottom: 8px;
  
  @media screen and (max-width: 900px) {
    width: 250px;
    height: 250px;
  }
`

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
`

const StrikeText = styled.s<TextProps>`
  font-size: ${p => p.fontSize}px;
  font-weight: ${p => p.fontWeight};
  margin-bottom: ${p => p.marginBottom}px;
  color: #181833;
`

const Sale = styled.div`
  top: -50px;
  right: -21px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Exclusive = styled.div`
  top: -148px;
  right: -110px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.35);
`

const SaleText = styled.div`
  position: absolute;
  right: 15px;
  top: 42px;
  border-radius: 50px 0 0 50px;
  font-weight: bold;
  font-size: 20px;
  width: max-content;
  color: white;
  padding: 6px;
  padding-left: 12px;
  padding-right: 12px;
`

const ReadeMoreButton = styled.a`
  position: absolute;
  bottom: 12px;
  left: 32px;
  color: rgba(24, 24, 51, .5);
  font-size: 16px;
  text-decoration: underline;
`

const TileButton = styled.button`
  position: absolute;
  background: none;
  border-radius: 30px;
  outline: none !important;
  border: none !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

const NFTProjectTile = (props: NFTTilePropType) => {
  const {project, isProjectCollection} = props
  const history = useHistory();

  const {locale} = useContext(LocaleContext)

  const isSkeleton = !(project)

  if (project && project.name && AllProjects[project.name].isHidden) {
    return null
  }

  return (
    <>
      {isSkeleton && <TileWrapper isProjectCollection={isProjectCollection} className='skeleton'/>}
      {project && project.name !== '' &&
        <TileWrapper
          isProjectCollection={isProjectCollection}
          className={`${isSkeleton ? 'skeleton' : ''}`}
        >
          <TileButton
            onClick={!isProjectCollection ? () => history.push(`/projects/${project.name}`) : () => {
            }}
          />
          {!isProjectCollection && !!AllProjects[project.name].fakePrice &&
            <Sale>
              <SaleText>-{AllProjects[project.name].salePercentage}</SaleText>
              <LimitedOfferIcon/>
            </Sale>
          }
          {!isProjectCollection && !AllProjects[project.name].fakePrice &&
            <Exclusive>
              <ExclusiveIcon/>
            </Exclusive>
          }
          <NFTCoverWrapper onClick={!isProjectCollection ? () => history.push(`/projects/${project.name}`) : () => {}}>
            <Logo loop muted autoPlay playsInline>
              <source src={`${AllProjects[project.name].creativeLink}`}/>
            </Logo>
          </NFTCoverWrapper>
          <NFTInfoWrapper>
            <Text>
              <Text fontSize={20} fontWeight={600}>{AllProjects[project.name].name}</Text>
              <Text fontWeight={600} fontSize={24}
                    color={'#5790FF'}>5 {localized(texts.tiers, locale)}</Text>
            </Text>
            <FlexWrapper>
              <FlexWrapper>
                <Text fontSize={14} fontWeight={400}>
                  {localized(texts.priceForShare, locale)}:
                </Text>
                <StrikeText fontSize={14} fontWeight={400}>
                  {`${AllProjects[project.name].fakePrice}`}
                </StrikeText>
              </FlexWrapper>
              <Text fontSize={18} fontWeight={700}>
                {`${AllProjects[project.name].priceForShare}`}
              </Text>
            </FlexWrapper>
          </NFTInfoWrapper>
          <NFTDescription>
            <div>
              {localized(AllProjects[project.name].description, locale)}
            </div>
          </NFTDescription>
          <ReadeMoreButton href={`${AllProjects[project.name].presentationLink}`} target='_blank'
                           rel="noreferrer">{localized(texts.viewPresentation, locale)}</ReadeMoreButton>
        </TileWrapper>
      }
    </>
  )
};

NFTProjectTile.defaultProps = NFTTileDefaultProps;

export default NFTProjectTile