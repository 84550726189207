import React from "react";
import styled from "styled-components";
import ReturnBack from "../../icons/ReturnBack";
import {useHistory} from "react-router-dom";

type ReturnBackPanelPropType = {
  href?: string,
}

const ReturnBackPanelDefaultProps = {
  href: undefined
}

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const ReturnBackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 112px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #E8E8EB;
  border-radius: 40px;
  cursor: pointer;
  z-index: 1000;
`

const ReturnBackPanel = (props: ReturnBackPanelPropType) => {
  const {href} = props
  const history = useHistory();

  const goBack = () => {
    if(!href){
      history.goBack()
    }else{
      history.push(href);
    }
  }

  return (
    <Container>
      <ReturnBackButton onClick={goBack}>
        <ReturnBack/>
      </ReturnBackButton>
    </Container>
  )
};

ReturnBackPanel.defaultProps = ReturnBackPanelDefaultProps

export default ReturnBackPanel