export default {
  approveButton: {
    en: 'Approve',
    ja: '承認'
  },
  placeholderEmail: {
    en: 'Email',
    ja: '電子メール'
  },
  placeholderYourAllocation: {
    en: 'Your allocation in BUSD',
    ja: 'BUSD での割り当て'
  },
  tooltipPleaseEnterCorrectEmail: {
    en: 'Please enter a correct email',
    ja: '正しいメールアドレスを入力してください。'
  },
  defaultButtonMax: {
    en: 'Max',
    ja: 'マックス'
  },
  tooltipAmountCannotExceedMaxAllocation: {
    en: 'Amount cannot exceed max allocation',
    ja: '金額は最大配分額を超えることはできません。'
  },
  allocateButton: {
    en: 'Buy allocation',
    ja: '購入配分'
  },
  notificationInsufficientTitle:{
    en: 'Insufficient balance',
    ja: 'バランス不足'
  },
  successNotification: {
    en: "You have successfully allocated",
    ja: "割り当てに成功しました"
  },
  transactionFailedTitle: {
    en: "Transaction Failed",
    ja: "処理に失敗しました"
  },
  transactionFailedSubtitle: {
    en: "Please try again later",
    ja: "後でもう一度やり直してください"
  },
  connectWalletToAllocate: {
    en: 'Please connect your MetaMask or Trust Wallet to buy an allocation\n',
    ja: 'MetaMaskまたはTrust Walletを接続して、アロケーションを購入してください。'
  },
}
