export default {
  viewPresentation: {
    en: "View presentation",
    ja: "プレゼンテーションを見る"
  },
  priceForShare: {
    en: "Price for share",
    ja: "株の価格"
  },
  tiers: {
    en: "TIERS",
    ja: "階層"
  },
  left: {
    en: "left",
    ja: "残り"
  }
}
