import React, {useContext, useEffect, useState} from "react";

import StandardAppContainer from "./Standard/StandardAppContainer";
import Main from "./pages/Main";
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";
import HeaderUserManualButton from "./components/HeaderUserManualButton";
import CurrentNFT from "./pages/CurrentNFT";
import CollectionContext from "./utils/CollectionContext";
import {useWeb3React} from "@web3-react/core";
import {useAllocationMarketplaceContract} from "./hooks/useMarketplaceContract";
import {useHistory} from 'react-router-dom';
import ProjectContext from "./utils/ProjectsContext";
import {NFT, ProjectsDict} from "./types";
import LocaleContext from "./Standard/LocaleContext";
import {localized} from "./Standard/utils/localized";
import {HeaderButton} from "./Standard/components/WalletConnector";
import texts from './localization';
import CollectionIcon from './Standard/icons/CollectionIcon'
import ProjectCollection from "./pages/ProjectCollection";
import Collection from "./pages/Collection";

export const App = () => {
  const [allProjects, setAllProjects] = useState<ProjectsDict>({})
  const [tokens, setTokens] = useState()

  const {active, account} = useWeb3React()

  const [collectionBubbleValue, setCollectionBubbleValue] = useState(0)
  const [collectionOpen, setCollectionOpen] = useState(false)

  async function updateUserBalance() {
    const NFTIdsArray = await marketplaceContract.methods.getProjects().call()
    setCollectionBubbleValue(NFTIdsArray.length)
  }

  const marketplaceContract = useAllocationMarketplaceContract()

  async function getAllProjects() {
    let newProjects: ProjectsDict = {}
    for (let i = 0; i < 99999; i++) {
      let newProject: NFT
      try {
        newProject = {...(await marketplaceContract.methods.projects(i).call()), projectId: i}
      } catch (e) {
        break
      }
      newProjects[newProject.name] = {...newProject}
    }

    setAllProjects(newProjects)
  }

  useEffect(() => {
    getAllProjects()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  useEffect(() => {
    if (active) {
      updateUserBalance()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  const CollectionButton = (props: {onClick: () => void}) => {
    const history = useHistory()
    const {locale} = useContext(LocaleContext)

    return (
      <button
        className={`connection-button`}
        onClick={() => {
          props.onClick()
          history.push('/collection')
        }}
      >
        <CollectionIcon/>
        <div style={{marginRight: 12}}/>
        {localized(texts.collection, locale)}
      </button>
    )
  }

  const buttons: HeaderButton[] = [
    CollectionButton
  ]

  return (
    <CollectionContext.Provider
      value={{
        bubbleCount: collectionBubbleValue,
        setCollectionBubbleValue: setCollectionBubbleValue,
        collectionOpen: collectionOpen,
        setCollectionOpen: setCollectionOpen
      }}
    >
      <Router>
        <StandardAppContainer
          logoHref={'/'}
          locales={['en', 'ja']}
          isDarkBG={false}
          version={"1.0.2"}
          connectorButtons={buttons}
          headerButtons={[
            <HeaderUserManualButton key={"header-user-manual-button"}/>
          ]}
        >
          <ProjectContext.Provider value={{setProjects: setAllProjects, projects: allProjects, tokens, setTokens}}>
            <Route component={Main} path={'/'} exact/>
            <Route component={ProjectCollection} path={'/projects/:id'} exact />
            <Route component={Collection} path={'/collection'} exact/>
            <Route component={CurrentNFT} path={'/nfts/:id'} exact/>
          </ProjectContext.Provider>
        </StandardAppContainer>
      </Router>
    </CollectionContext.Provider>
  );
};
