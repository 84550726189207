import React, {useContext} from "react";
import './index.css'
import NFTProjectTile from "../../components/NFTProjectTile";
import styled from 'styled-components'
import ProjectsContext from "../../utils/ProjectsContext";
import {localized} from "../../Standard/utils/localized";
import texts from './localization'
import LocaleContext from "../../Standard/LocaleContext";
import GradientCircles from "../../Standard/decorations/GradientCircles";
import NFTMarketplaceSubHeader from "../../components/SubHeader/variants/NftMarketplace";
import {AllProjects} from "../../mocks/AllProjects";

const Wrapper = styled.div`
  margin-top: 56px;
  max-width: 1500px;


  padding-left: 80px;
  padding-right: 80px;
  @media screen and (max-width: 900px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`

const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
  z-index: 1;
`

const ProjectFlex = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  gap: 40px;
  justify-content: center;
  flex-wrap: wrap;

  @media screen and (max-width: 900px) {
    justify-content: center !important;
  }
`

const Main = () => {
  const {locale} = useContext(LocaleContext)
  const {projects} = useContext(ProjectsContext)

  return (
    <>
    <Container>
      <NFTMarketplaceSubHeader subtitle={localized(texts.subtitle, locale)}/>
      <Wrapper>
        <ProjectFlex>
          {Object.keys(projects).length ?
            <>
              {Object.keys(AllProjects).map((name) => {
                if(projects[name]){
                  return (
                    <NFTProjectTile key={name} project={projects[name]}/>
                  )
                }else{
                  return null
                }
              })}
            </>
            :
            <>
              {/* @ts-ignore */}
              <NFTProjectTile project={undefined}/>
              {/* @ts-ignore */}
              <NFTProjectTile project={undefined}/>
              {/* @ts-ignore */}
              <NFTProjectTile project={undefined}/>
              {/* @ts-ignore */}
              <NFTProjectTile project={undefined}/>
            </>
          }
        </ProjectFlex>
      </Wrapper>
    </Container>
      <GradientCircles/>
    </>
  )
};

export default Main

