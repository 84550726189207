import React, {useContext} from "react";
import {wei2eth} from "../../Standard/utils/common";
import {useHistory} from "react-router-dom";
import {Token} from "../../types";
import styled from "styled-components"
import texts from './localization'
import LocaleContext from "../../Standard/LocaleContext";
import {localized} from "../../Standard/utils/localized";

type NFTTilePropType = {
  token?: Token,
  projectName?: string
}


const NFTTileWrapper = styled.div`
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-width: max-content;
  margin-bottom: 16px;
  clip-path: content-box;
  border: 1px solid #E8E8EB;
`

const NFTProjectLimit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 12px;
  top: 10px;
  padding-right: 6px;
  padding-left: 6px;
  height: 30px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  color: #fff;
`

const NFTProjectArtwork = styled.video`
  width: 240px;
  height: 220px;
  overflow: hidden;
  object-fit: cover;
  transition: 0.3s;
  display: block;
  z-index: 0;
  cursor: pointer;
  transform: scale(1);

  &:hover {
    transform: scale(1.2)
  }

  @media screen and (max-width: 900px){
    transform: scale(1) !important;
  }
`

const Price = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: rgba(24,24,51, .5);
  border-radius: 8px;
  height: 20px;
  margin-bottom: 5px;
`

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 20px;
`

const NFTTileDefaultProps = {}

const NFTTileSimple = (props: NFTTilePropType) => {
  const {token, projectName} = props
  const history = useHistory();
  const {locale} = useContext(LocaleContext)

  return (
    <FlexWrapper>
      <NFTTileWrapper
        onClick={token ? () => history.push(`/nfts/${projectName}-${token.nftId}`): ()=>{}}
        className={`${!token ? 'skeleton': ''}`}
      >
        <NFTProjectArtwork playsInline autoPlay loop muted>
          {!!token &&
            <source src={`${token.nftCreativeLink}`} type="video/webm"/>
          }
        </NFTProjectArtwork>
        <NFTProjectLimit>
          {`${token ? (+token.allocationLimit - +token.allocationAmount): 0} 
          ${localized(texts.left, locale)} `}
        </NFTProjectLimit>
      </NFTTileWrapper>
      <Price className={`${!token ? 'skeleton': ''}`}>{`${localized(texts.allocate, locale)} ${parseInt(wei2eth(token ? +token.maxAllocation * 100: 0).toFixed(0).toString())/100} BUSD`}</Price>
      <Price className={`${!token ? 'skeleton': ''}`}>{`${localized(texts.basePrice, locale)} ${wei2eth(token ? token.price: 0)} BUSD`}</Price>
    </FlexWrapper>
  )
};

NFTTileSimple.defaultProps = NFTTileDefaultProps

export default NFTTileSimple