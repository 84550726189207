import React, {useContext, useEffect} from "react";
import texts from './localization'
import LocaleContext from "../../Standard/LocaleContext";
import {localized} from "../../Standard/utils/localized";
import Text from "../Text";
import {AllProjects} from "../../mocks/AllProjects";
import {wei2eth} from "../../Standard/utils/common";
import styled from "styled-components";
import {useParams} from "react-router-dom";
import {useBalanceOfBUSD} from "../../hooks/useBalance";
import {Token} from "../../types";
import {useWeb3React} from "@web3-react/core";

type CurrentNFTDescriptionPropType = {
  token: Token | undefined;
  balance: string;
  updateBalance: () => void
}

const CurrentNFTDescriptionDefaultProps = {}

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 5px;
`

const CurrentNFTDescription = (props: CurrentNFTDescriptionPropType) => {
  const {locale} = useContext(LocaleContext)
  const {active} = useWeb3React()
  const {token, balance, updateBalance} = props

  const params: { id: string } = useParams()

  useEffect(() => {
    if (active) {
      updateBalance()
    }
  }, [active])

  return (
    <div className='mb-5'>
      <Text
        fontWeight={700}
        fontSize={40}
        marginBottom={20}
      >
        {`${AllProjects[params.id.slice(0, params.id.length - 2)].name}
                   ${localized(texts.tier, locale)} ${+params.id.slice(params.id.length - 1) + 1}`}
      </Text>
      <TextWrapper>
        <Text
          fontWeight={600}
          fontSize={20}
          marginBottom={5}
        >{localized(texts.balanceInBUSD, locale)}</Text>
        <Text className={`${!token && 'skeleton'}`}>
          <Text fontWeight={600} fontSize={24}>{`${wei2eth(balance).toFixed(2)}`}</Text>
          <Text fontWeight={400} fontSize={22}>BUSD</Text>
        </Text>
      </TextWrapper>
      <TextWrapper>
        <Text
          fontWeight={600}
          fontSize={20}
          marginBottom={5}
        >{localized(texts.basePrice, locale)}</Text>
        <Text className={`${!token && 'skeleton'}`}>
          <Text fontWeight={600} fontSize={24}>{`${token ? wei2eth(token.price) : ''}`}</Text>
          <Text fontWeight={400} fontSize={22}>BUSD</Text>
        </Text>
      </TextWrapper>
      <TextWrapper>
        <Text
          fontWeight={600}
          fontSize={20}
          marginBottom={5}
        >{localized(texts.maxAllocation, locale)}</Text>
        <Text className={`${!token && 'skeleton'}`}>
          <Text fontWeight={600} fontSize={24}>{`${token ? wei2eth(token.maxAllocation) : ''}`}</Text>
          <Text fontWeight={400} fontSize={22}>BUSD</Text>
        </Text>
      </TextWrapper>
    </div>
  )
};

CurrentNFTDescription.defaultProps = CurrentNFTDescriptionDefaultProps

export default CurrentNFTDescription