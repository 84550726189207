type AllProjectsDict = {[key: string]: {name: string, fakePrice: string, salePercentage: string, description: {}, priceForShare: string, creativeLink: string, isHidden?: boolean, presentationLink: string, nftsCreativeLinks: string[]}}

export const AllProjects: AllProjectsDict = {
  "Animoca Brands": {
    name: "Animoca Brands",
    priceForShare: "$3",
    fakePrice: "",
    salePercentage: "",
    presentationLink: "/presentations/AnimocaBrands.pdf",
    description: {
      en: "Animoca Brands is a Hong Kong-based game software company and venture capital company founded in 2014 by Yat Siu. Animoca Brands' has over 340 cryptocurrency-related holdings, this include Axie Infinity, OpenSea, Dapper Labs and CryptoKitties. Animocas portfolio investments is worth $1.5 billion in crypto and blockchain gaming across 340 companies.",
      ja: "Animoca Brands は、Yat Siu によって 2014 年に設立された、香港を拠点とするゲーム ソフトウェア会社およびベンチャー キャピタル会社です。 Animoca Brands は、Axie Infinity、OpenSea、Dapper Labs、CryptoKitties など、340 以上の仮想通貨関連の資産を保有しています。 Animocas のポートフォリオへの投資は、340 社の仮想通貨およびブロックチェーン ゲームで 15 億ドルの価値があります。"
    },
    creativeLink: '/creative/AnimocaBrands_cover.mp4',
    nftsCreativeLinks: [
      'creative/animocaBrands/t1.mp4',
      'creative/animocaBrands/t2.mp4',
      'creative/animocaBrands/t3.mp4',
      'creative/animocaBrands/t4.mp4',
      'creative/animocaBrands/t5.mp4',
    ]
  },
  "Bitpay": {
    name: "Bitpay",
    priceForShare: "$7.65",
    fakePrice: "$8.5",
    salePercentage: "10%",
    presentationLink: "/presentations/Bitpay.pdf",
    description: {
      en: "BitPay is a bitcoin payment service provider. BitPay provides Bitcoin and Bitcoin Cash payment processing services for merchants.",
      ja: "BitPayは、ビットコイン決済サービスを提供する企業です。BitPayは、ビットコインとビットコインキャッシュの決済処理サービスをマーチャント向けに提供しています。"
    },
    creativeLink: '/creative/Bitpay_cover.mp4',
    nftsCreativeLinks: [
      'creative/bitpayNfts/t1.mp4',
      'creative/bitpayNfts/t2.mp4',
      'creative/bitpayNfts/t3.mp4',
      'creative/bitpayNfts/t4.mp4',
      'creative/bitpayNfts/t5.mp4',
    ]
  },
  "Metamask": {
    name: "MetaMask",
    priceForShare: "$105",
    fakePrice: "$150",
    salePercentage: "30%",
    presentationLink: "/presentations/ConsenSys.pdf",
    description: {
      en: "ConsenSys is a blockchain software technology company, the developer and owner of MetaMask. MetaMask is a software cryptocurrency wallet used to interact with the Ethereum blockchain.",
      ja: "ConsenSysはブロックチェーンソフトウェア技術企業であり、MetaMaskの開発者であり所有者です。MetaMaskは、Ethereumブロックチェーンと相互作用するために使用されるソフトウェア暗号通貨ウォレットです。"
    },
    creativeLink: '/creative/Metamask_cover.mp4',
    nftsCreativeLinks: [
      'creative/metamaskNfts/t1.mp4',
      'creative/metamaskNfts/t2.mp4',
      'creative/metamaskNfts/t3.mp4',
      'creative/metamaskNfts/t4.mp4',
      'creative/metamaskNfts/t5.mp4',
    ]
  },
  "kraken": {
    name: "Kraken",
    priceForShare: "$44.8",
    fakePrice: "$64",
    salePercentage: "30%",
    presentationLink: "/presentations/Kraken.pdf",
    description: {
      en: "Kraken is a cryptocurrency exchange and bank, founded in 2011, one of the first bitcoin exchanges to be listed on Bloomberg Terminal and is valued at $11B, as of mid-summer 2022.",
      ja: "Krakenは、2011年に設立された暗号通貨取引所兼銀行で、Bloomberg Terminalに最初に掲載されたビットコイン取引所の1つで、2022年夏中旬現在、$11Bと評価されています。"
    },
    creativeLink: '/creative/Kraken_cover.mp4',
    nftsCreativeLinks: [
      'creative/krakenNfts/t1.mp4',
      'creative/krakenNfts/t2.mp4',
      'creative/krakenNfts/t3.mp4',
      'creative/krakenNfts/t4.mp4',
      'creative/krakenNfts/t5.mp4',
    ]
  },
  // "Ledger": {
  //   name: "Ledger",
  //   priceForShare: "$800",
  //   fakePrice: "$800",
  //   salePercentage: "0%",
  //   presentationLink: "/presentations/Ledger.pdf",
  //   isHidden: true,
  //   description: {
  //     en: "Ledger is a fast paced, growing company developing security and infrastructure solutions for cryptocurrencies as well as blockchain applications for individuals and companies, by leveraging a distinctive, proprietary technology.",
  //     ja: ""
  //   },
  //   creativeLink: '/creative/Ledger_cover.gif',
  //   nftsCreativeLinks: [
  //     'creative/krakenNfts/t1.mp4',
  //     'creative/krakenNfts/t2.mp4',
  //     'creative/krakenNfts/t3.mp4',
  //     'creative/krakenNfts/t4.mp4',
  //     'creative/krakenNfts/t5.mp4',
  //   ]
  // },
  "Thrasio": {
    name: "Thrasio",
    priceForShare: "$10.125",
    fakePrice: "$20.25",
    salePercentage: "50%",
    presentationLink: "/presentations/Thrasio.pdf",
    description: {
      en: "Thrasio is a next-generation consumer goods company reimagining how the world's most-loved products become accessible to everyone, Amazon aggregator.",
      ja: "Thrasioは、世界で最も愛されている製品を誰もが手に入れられるようにする方法を再構築する次世代消費財メーカーで、Amazonアグリゲーターです。"
    },
    creativeLink: '/creative/Thrasio_cover.mp4',
    nftsCreativeLinks: [
      'creative/thrasioNfts/t1.mp4',
      'creative/thrasioNfts/t2.mp4',
      'creative/thrasioNfts/t3.mp4',
      'creative/thrasioNfts/t4.mp4',
      'creative/thrasioNfts/t5.mp4',
    ]
  },
  "Chainalysis":{
    name: "Chainalysis",
    priceForShare: "$32",
    fakePrice: "$40",
    salePercentage: "20%",
    presentationLink: "/presentations/Chainalysis.pdf",
    description: {
      en: "Chainalysis is a blockchain data and software service provider that creates tools and research for government agencies, crypto exchanges, and financial institutions.",
      ja: "Chainalysis は、政府機関、仮想通貨取引所、金融機関向けのツールと調査を作成するブロックチェーン データおよびソフトウェア サービス プロバイダーです。"
    },
    creativeLink: '/creative/Chainalysis_cover.mp4',
    nftsCreativeLinks: [
      'creative/chainalysisNfts/t1.mp4',
      'creative/chainalysisNfts/t2.mp4',
      'creative/chainalysisNfts/t3.mp4',
      'creative/chainalysisNfts/t4.mp4',
      'creative/chainalysisNfts/t5.mp4',
    ]
  }
}
