export default {
  collection:{
    en: 'Collection',
    ja: 'コレクション'
  },
  subheaderTitle: {
    en: 'NFT Store',
    ja: 'NFT ストア'
  },
}
