import React, {useContext, useEffect, useState} from "react";
import './index.css';
import {NFT, Token} from "../../types";
import NFTTileSimple from "../../components/NFTTileSimple";
import styled from "styled-components";
import {useParams} from "react-router-dom";
import {AllProjects} from "../../mocks/AllProjects";
import ProjectsContext from "../../utils/ProjectsContext";
import ReturnBackPanel from "../../components/ReturnBackPanel";
import CurrentProjectWrapper from "../../components/CurrentProjectWrapper";
import NFTMarketplaceSubHeader from "../../components/SubHeader/variants/NftMarketplace";
import GradientCircles from "../../Standard/decorations/GradientCircles";
import {Contract} from "ethers";
import CurrentNft from "../../contract/CurrentNft.json";
import {useWeb3React} from "@web3-react/core";
import {useWeb3} from "../../Standard/hooks/useCommonContracts";

const ProjectCollectionPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  z-index: 4;
`

const Container = styled.div`
  padding-left: 80px;
  padding-right: 80px;
  max-width: 1500px;

  @media screen and (max-width: 900px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`

const ProjectCollection = () => {
  const params: { id: string } = useParams()
  const {account} = useWeb3React()
  const web3 = useWeb3();
  const {projects, tokens, setTokens} = useContext(ProjectsContext)

  const [project, setProject] = useState<NFT>()

  const getAllNfts = async (project: NFT) => {
    const NFTArrayFromContract: Token[] = []

    let newNftContract: Contract | undefined

    const abi = CurrentNft.abi;
    if (project.projectAddress) {
      // @ts-ignore
      newNftContract = new web3.eth.Contract(abi, project.projectAddress);
    }

    for (let i = 0; i < 99999; i++) {
      let newToken: Token
      try {
        newToken = {
          ...(await newNftContract?.methods.tokensInfo(`${i}`).call()),
          nftId: i,
          nftCreativeLink: AllProjects[project.name].nftsCreativeLinks[i],
          projectId: project.projectId
        }
        if (account) {
          newToken = {
            ...newToken,
            userAllocation: await newNftContract?.methods.userAllocation(`${i}`, account).call()
          }
        }
      } catch(e) {
        break
      }
      NFTArrayFromContract.push(newToken)
      // @ts-ignore
      setTokens({...tokens , [`${project.name}`]: NFTArrayFromContract})
    }

    setProject({...project, tokens: NFTArrayFromContract})
  }

  useEffect(() => {
    const project = projects[params.id]
    setProject(project)
  }, [Object.keys(projects).length, params.id])

  useEffect(() => {
    if (project) {
      getAllNfts(project)
    }
  }, [project?.name])

  return (
    <>
      <ProjectCollectionPage>
        <NFTMarketplaceSubHeader
          subtitle={AllProjects[params.id].name}
        />
        <Container>
          <ReturnBackPanel href={`/`}/>
          <CurrentProjectWrapper project={project}>
            {
              project && project.tokens ?
                <>
                  {project.tokens?.filter(token => !token.pauseMint).map((token: Token) => {
                    return (
                      <NFTTileSimple
                        key={token.nftId}
                        projectName={project.name}
                        token={token}/>
                    )
                  })}
                </>
                :
                <>
                  <NFTTileSimple/>
                  <NFTTileSimple/>
                  <NFTTileSimple/>
                  <NFTTileSimple/>
                  <NFTTileSimple/>
                </>
            }
          </CurrentProjectWrapper>
        </Container>
      </ProjectCollectionPage>
      <GradientCircles/>
    </>
  )
};

export default ProjectCollection

