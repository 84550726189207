import styled from "styled-components";


interface NFTArtworkProps {
  maxWidth?: number,
  maxWidthMobile?: number
  minWidth?: number
  minHeights?: number,
  maxHeightMobile?: number,
  maxWidthAfter1000?: number,
  maxHeightAfter1000?: number,
  maxWidthAfter900?: number,
  maxHeightAfter900?: number,
}


export const ArtworkImage = styled.video<NFTArtworkProps>`
  max-width: ${p=> p.maxWidth || 430}px;
  width: 100%;
  border-radius: 16px;
  border: 1px solid #E8E8EB;
  ${p=> `min-width: ${p.minWidth}px;`}
  ${p=> `min-height: ${p.minHeights}px;`}
  object-fit: cover;

  @media screen and (max-width: 1240px) {
    max-width: ${p=> p.maxWidthMobile ? p.maxWidthMobile : 300}px;
    max-height: ${p=> p.maxHeightAfter1000 ? p.maxHeightMobile : 300}px;
    min-height: ${p=> p.maxWidthMobile ? p.maxWidthMobile : 300}px;
    min-width: ${p=> p.maxHeightAfter1000 ? p.maxHeightMobile : 300}px;
  }

  @media screen and (max-width: 1075px) {
    max-width: ${p=> p.maxWidthAfter1000 ? p.maxWidthAfter1000 : 300}px;
    max-height: ${p=> p.maxHeightMobile ? p.maxHeightMobile : 300}px;
    min-height: ${p=> p.maxWidthAfter1000 ? p.maxWidthAfter1000 : 300}px;
    min-width: ${p=> p.maxWidthAfter1000 ? p.maxWidthAfter1000 : 300}px;
  }

  @media screen and (max-width: 900px){
    max-width: ${p=> p.maxWidthAfter900 ? p.maxWidthAfter900 : 300}px;
    max-height: ${p=> p.maxHeightAfter900 ? p.maxHeightAfter900 : 300}px;
    min-height: ${p=> p.maxWidthAfter900 ? p.maxWidthAfter900 : 300}px;
    min-width: ${p=> p.maxHeightAfter900 ? p.maxHeightAfter900 : 300}px;
  }
`