import React, {useContext} from "react";
import texts from './localization'
import LocaleContext from "../../Standard/LocaleContext";
import {localized} from "../../Standard/utils/localized";
import styled from "styled-components";

const UserManualLink = styled.a`
  transition: width .3s;
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 500;
  font-size: 16px;
  border-radius: 23px;
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  outline: none !important;
  overflow: hidden;
  background: white;
  backdrop-filter: blur(30px);
  color: black;
  border: 1px solid #E8E8EB;
  z-index: 1;
`

const UserManualText = styled.div`
  transition: all .3s;
  position: absolute;
  width: max-content;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 100%;
  height: 40px;
  flex-shrink: 0;
`

const UserManualIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`

const ButtonContainer = styled.div`
  position: relative;
  height: 40px;

  &:hover ${UserManualText} {
    right: calc(100% - 110px);
  }
  
  &:hover ${UserManualLink} {
    width: 145px;
    border-radius: 23px;
    z-index: 0;
    padding-left: 16px;
  }
`

const HeaderUserManualButton = () => {
    const {locale} = useContext(LocaleContext)

    return (
      <ButtonContainer>
        <UserManualLink
          href="presentations/UserManual.pdf"
          target={'_blank'}>
          <UserManualText>
            {localized(texts.howItWorks, locale)}
          </UserManualText>
          <UserManualIcon>
            ?
          </UserManualIcon>
        </UserManualLink>
      </ButtonContainer>
    )
};

export default HeaderUserManualButton